@import '../../styles/vars';

.vendorlist {

  &__wrapper {
    min-width: 900px;
    width: $maxTableWidth;
    margin: 0 auto;
  }

  &__search {
    display: flex;
    padding: 0 0 1.5em 0;
  }

  &__createButton {
    flex-grow: 1;
  }

  &__filter {
    padding-right: 2px;
  }

  &__actionColumn {
    width: 5rem;
  }

  &__noVendorMessage {
    padding: 1.5em 0;
    text-align: center;
  }

  &__companyNameWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem 0;

    &__firstAndLastName {
      font-size: 0.75rem;
      font-style: italic;
    }
  }

  &__pill {
    padding: 0.4em .2em 0.2em .4em;
    background-color: $grey-3;
    border-radius: 50px;
    text-align: center;
    white-space: nowrap;
    color: #ffffff;
    box-shadow: 0 1.25px 2.5px rgba(0, 0, 0, 0.15);
    font-size: 0.85em;
    font-weight: 600;

    &--yellow {
      background-color: $bubblegum-yellow;
    }

    &--vendorStatus {
      max-width: 180px;
    }
  }
}
