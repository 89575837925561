
.aggregateModal {
  background: white;
  border-radius: 2px;
  padding: 20px;

  &__title {
    padding: 1em 0;
  }

  &__buttons {
    display: block;
    float: right;
  }

  &__textarea {
    width: 100%;
  }

  &__inputs {
    display: block;
  }

  &__grid {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding: 1rem 0;

    &__originalDamId {
      flex-grow: 1;
    }

    &__damAssetId {
      flex-grow: 2;
    }

    &__vendorKey {
      flex-grow: 3;
    }

    &__serialNumber {
      flex-grow: 3;
    }

    &__rawEvents {
      flex-grow: 0;
      display: flex;
      width: 200px;
      align-items: center;
    }
  }
}
