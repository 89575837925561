.modal {

    &__closeButton {
        width: 40px;
        height: 40px;
        text-align: center;
        color: #fff;
        border-radius: 50px;
        padding: 7px 0 0 0;
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 10;
        transition: all 0.3s;

        &:hover {
            color: #333;
            background-color: #fff;
        }
    }

    &__content {
        padding: 1em 2em 2em 2em;
        width: 50em;
        color: #fff;
        z-index: 50;

        h3, h6 {
            margin-top: 0;
            font-size: 1.575em;
            color: #ffffff;
            padding: 1em 0;
            font-weight: 300;
        }

        h6 {
            font-size: 1.35em;
            font-weight: 600;
        }

        p {
            color: #ffffff;
        }
    }

    &__background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        background: linear-gradient(180deg, rgba(220, 220, 220, 0.45) 3.62%, rgba(25, 25, 25, 0.6) 100%);
    }
}
