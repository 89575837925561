@import '../../styles/vars';

.vendorDetails {
  &__wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 3em;
  }

  &__headline {
    padding-top: 1em;
  }

  &__progressbar {
    padding: 20px 0;
  }

  &__progress {
    display: flex;
    justify-content: space-between;

    & a {
      transition: ease-in-out all 0.3s;
      text-decoration: none;
      color: $black;
    }

    & a:hover {
      color: $grey-3;
    }

    &__section {
      flex: 23%;
      flex-grow: 0;
    }

    &__label {
      padding-bottom: 0.5em;
      font-size: smaller;
    }
  }

}



