@import '../../../../styles/vars.scss';
.popoverContent {
    .item {
        font-size: 16px;
        display: flex;
        flex-direction: column;
        padding: 5px 10px;
        min-width: 350px;
        max-width: 520px;

    }
    .batchJob {
        display: flex;
        align-items:center;
    }
    .failedJobs {
        font-size: 0.85em;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
    }

    .jobTitle {
        display: inline-block;
        padding-right: 3px;
        white-space: nowrap;
        max-width: 450px;
        overflow: hidden;
        text-overflow: ellipsis;


    }

    .status {
        padding-left: 4px;
        white-space: nowrap;

        &__success {
            color: $bubblegum-green__dark;
        }
        &__pending {
            color: $bubblegum-blue;
        }
        &__failed {
            color: $bubblegum-red;
        }
    }
}