@import 'vars.scss';
@import 'typography';
@import 'fonts';

/* css reset */

* {
  font-family: 'MontAS', sans-serif;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  background-color: $white;
  color: $black;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  outline: 0;
  color: $bubblegum-red;

  &:hover, &:active, &:focus {
    text-decoration: underline;
  }
}

.MuiInputBase-root,
.MuiFormLabel-root,
.MuiTypography-body1 {
  font-family: 'MontAS', sans-serif !important;
}

.MuiButton-textSecondary:hover {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #0000ff !important;
}


[class*="MTableToolbar-searchField"] {
  padding-top: 8px !important;
  padding-right: 10px !important;

}


.MuiPaper-elevation1 {
  box-shadow: 0 2px 10px -1px rgba(0,0,0,0.1), 0px 1px 8px 0px rgba(0,0,0,0.1), 0px 1px 10px 0px rgba(0,0,0,0.06) !important;
}


 .MuiPaper-rounded {
   border-radius: 8px !important;
 }

/* LAYOUT */

.wrapper {
  padding: 0 2.5rem;
  margin-top: 4rem;
  min-height: 84vh;
}

.logo {
  max-height: 13rem;
  pointer-events: none;
}

.login {
  margin: auto;
}


/* GENERIC TABLE STYLES */

.table__head {
  background-color: $bubblegum-red;

  & th {
    color: #ffffff;
    font-weight: 600;
    line-height: 1.4em !important;
  }
}

/* TEXT UTILITIES */

.nowrap {
  white-space: nowrap;
}

