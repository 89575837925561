@import '../../../styles/vars';

.purchaseFilter {
  display: grid;
  justify-content: flex-start;
  transition: all .3s;
}

.purchaseFilterCard {
  transition: all .2s;
}

.purchaseFilterSearchByIdWrapper {
  display: flex;
  padding-left: 2em;
  justify-content: space-between;
  align-items: center;
  transition: all .3s;
  height: 100px;
}

.purchaseFilterUsagesWrapper {
  display: flex;
  padding-left: 2em;
  justify-content: space-between;
  align-items: center;
  transition: all .3s;
  height: 100px;

  @media (max-width: $purchasefilter-breakpoint) {
    display: grid;
    grid-template-areas: "datepickers vendorAndSwitches buttons";
    margin: 1rem 0;
  }

  &__label {
    padding-right: 1em;
  }

  &__vendorAndTogglesSection {

    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: $purchasefilter-breakpoint) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__datePickers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    padding-right: 1em;

    div {
      input {
        min-width: 6em;
      }
    }

    @media (max-width: $purchasefilter-breakpoint) {
      display: flex;
      flex-direction: column;

      .MuiFormControl-marginNormal {
        margin: 0;
      }
    }

  }

  &__togglesWrapper {
    @media (max-width: $purchasefilter-breakpoint) {
      display: flex;
    }
  }
}

.purchaseFilterUsagesToggles {
  margin-left: 2em;

  &:first-child {
    @media (max-width: $purchasefilter-breakpoint) {
      margin-left: 0;
    }
  }
}

.purchaseFilterUsagesButtons {
  display: flex;
  gap: 0 0.5em;
  padding: 0 2em 0 2em;
  min-width: 270px;
  justify-content: space-between;

  @media (max-width: $purchasefilter-breakpoint) {
    flex-direction: column;
    gap: 0.5rem;
  }

  &--search {
    background-color: $bubblegum-red !important;
    color: #ffffff !important;

    &:hover {
      background-color: #FF0954 !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }

  &--reset {
    background-color: #4f4f4f !important;
    color: #ffffff !important;

    &:hover {
      background-color: $black !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }
}

.purchaseFilterCreditAdviceWrapper {
  display: flex;
  padding-left: 2em;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $purchasefilter-breakpoint) {
    display: grid;
    grid-template-areas: "datepickers vendorAndSwitches buttons createCreditAdviceButton";
  }
}

.datePickers {
  display: flex;
  justify-content: space-between;

  div {
    margin-right: 1em;
    max-width: 15em;
  }
}

.purchaseFilterUsagesWrapper__vendorPicker,
.vendorPicker {
  margin-top: -9px;

  @media (min-width: $purchasefilter-breakpoint) {
    width: 15em;
  }
}

.purchaseFilterCreditAdviceButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  padding-left: 2em;

  &--search {
    background-color: $bubblegum-red !important;
    color: #ffffff !important;

    &:hover {
      background-color: #FF0954 !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }

  &--reset {
    background-color: #4f4f4f !important;
    color: #ffffff !important;

    &:hover {
      background-color: $black !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }
}

.purchaseFilterSeachByIdButtons {
  display: flex;
  gap: 0 0.5em;
  padding: 0 2em 0 2em;
  width: 270px;
  justify-content: space-between;

  &--search {
    background-color: $bubblegum-red !important;
    color: #ffffff !important;

    &:hover {
      background-color: #FF0954 !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }

  &--reset {
    background-color: #4f4f4f !important;
    color: #ffffff !important;

    &:hover {
      background-color: $black !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }
}

.purchaseFilterCreateBatchJobButton {
  &--button {
    background: #6FCF97 !important;
    height: 100px;
    width: 125px;
    color: #ffffff !important;
    margin-left: 2em;
    border-radius: 0 5px 5px 0 !important;

    @media (max-width: $purchasefilter-breakpoint) {
      height: 120px;
      margin-left: 0;
    }
  }

  .Mui-disabled {
    background: $grey-5 !important;
    color: #333333 !important;
    text-decoration: line-through;
  }
}

.purchaseFilterTabs {
  display: flex;
  margin-left: 4em;

  &--usages {
    cursor: pointer;
    box-shadow: 1px -3px 6px 0px rgba(0, 0, 0, 0.03), -4px -5px 9px 0px rgba(0, 0, 0, 0.05) !important;
    background: $grey-5 !important;
    padding: .8em;
    border-radius: 8px 8px 0 0 !important;
  }

  &--creditadvice {
    cursor: pointer;
    box-shadow: 1px -3px 6px 0px rgba(0, 0, 0, 0.03), -4px -5px 9px 0px rgba(0, 0, 0, 0.05) !important;
    background: $grey-5 !important;
    padding: .8em;
    border-radius: 8px 8px 0 0 !important;
  }

  &--active {
    z-index: 3 !important;
    background-color: #ffffff !important;
  }
}
