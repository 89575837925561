@import '../../../styles/vars';

.creditAdviceFilter {
  display: grid;
  justify-content: flex-start;
  transition: all .3s;
}

.creditAdviceFilterCard {
  transition: all .2s;
}

.creditAdviceFilterWrapper {
  display: flex;
  padding-left: 2em;
  justify-content: space-between;
  align-items: center;
  transition: all .3s;
  height: 100px;

  @media (max-width: $purchasefilter-breakpoint) {
    display: grid;
    grid-template-areas: "datepickers vendorAndSwitches buttons";
    margin: 1rem 0;
  }

  &__datePickers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    padding-right: 1em;

    div {
      input {
        min-width: 6em;
      }
    }

    @media (max-width: $purchasefilter-breakpoint) {
      display: flex;
      flex-direction: column;

      .MuiFormControl-marginNormal {
        margin: 0;
      }
    }

  }
}

.creditAdviceFilterButtons {
  display: flex;
  gap: 0 0.5em;
  padding: 0 2em 0 2em;
  min-width: 270px;
  justify-content: space-between;

  @media (max-width: $purchasefilter-breakpoint) {
    flex-direction: column;
    gap: 0.5rem;
  }

  &--search {
    background-color: $bubblegum-red !important;
    color: #ffffff !important;

    &:hover {
      background-color: #FF0954 !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }

  &--reset {
    background-color: #4f4f4f !important;
    color: #ffffff !important;

    &:hover {
      background-color: $black !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }
}

.datePickers {
  display: flex;
  justify-content: space-between;

  div {
    margin-right: 1em;
    max-width: 15em;
  }
}

.creditAdviceFilterWrapper__vendorPicker,
.vendorPicker {
  margin-top: -9px;

  @media (min-width: $purchasefilter-breakpoint) {
    width: 15em;
  }
}

.creditAdviceFilterCreditAdviceButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  padding-left: 2em;

  &--search {
    background-color: $bubblegum-red !important;
    color: #ffffff !important;

    &:hover {
      background-color: #FF0954 !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }

  &--reset {
    background-color: #4f4f4f !important;
    color: #ffffff !important;

    &:hover {
      background-color: $black !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }
}

.creditAdviceFilterCreateBatchJobButton {
  &--button {
    background: #6FCF97 !important;
    height: 100px;
    width: 125px;
    color: #ffffff !important;
    margin-left: 2em;
    border-radius: 0 5px 5px 0 !important;

    @media (max-width: $purchasefilter-breakpoint) {
      height: 120px;
      margin-left: 0;
    }
  }

  .Mui-disabled {
    background: $grey-5 !important;
    color: #333333 !important;
    text-decoration: line-through;
  }
}
