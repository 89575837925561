@import "./src/styles/vars";

.detailsSection__wrapper {
    margin-top: 5em;
}

.vendorDetails__section {
    &__headline {
        padding: 1em 0 2em 0;
        margin: 2em 0 0 0 ;
        font-size: .8rem;
        line-height: 1.1rem;
    }

    &__container {
        margin-bottom: 2em;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3em;
        grid-auto-rows: 50px;

        @media (max-width: $screen-medium) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    /* to indicate that this is an empty cell in the grid; */
    &__empty {
        width: 1px;
    }
}
