@import '../../styles/vars';

.tablePagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .6em 0.4em;
}

.pagination {
  margin: 15px auto;
  justify-content: space-between;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  color: $bubblegum-red;
}

.pagination > li {
  padding: 0 .5em;
}

.pagination > li > a {
  padding: .8em;
  outline: none;
  font-weight: 600;
}

.pagination > li > a:hover {

  cursor: pointer;
  color: $bubblegum-red;
  text-decoration: none;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  outline: none;
  text-decoration: none;
}

.pagination > li > a, .pagination > li > span {
  color: #000
}

.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
  border-radius: unset;
  font-size: 1.75em;
  font-weight: 300;
  line-height: .9em;
  text-decoration: none;
}
