@import '../../styles/vars';

.ProgressBar {
  &__container {
    border-radius: 4px;
    background-color: #8f979c;
  }

  &__slider {
    border-radius: 4px;
    background-color: $bubblegum-red;
    width: 0; /* Adjust width via props.value */
    height: 20px;
    border: 0;
  }
}
