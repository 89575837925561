@import '../../styles/vars';

footer {
    margin-top: 4em;
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 2rem 0 1.5rem 0;
}

.footer__wrapper {
    display: flex;
    align-items: center;
}

.footer__credit {
    width: 49%;
    margin-right: .3em;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    position:relative;
    color:white;
    user-select: none;

    & div {
        text-align:right;
        height:55px;
        line-height:55px;

        & span:nth-child(1) {
            margin-right: .3rem;
            color:black;
        }

        & span:nth-child(2) {
            position:absolute;
            width:0;
            right:0;
        }
    }

    & img {
        width: 40px;
        vertical-align: middle;
    }
}

.scrollToTop__wrapper {
    position: absolute;
    bottom: 0;
    right: 0;

    & svg {
        transform: scale(.5);
    }
}

.scrollTop {
    position: fixed;
    width: 5rem;
    bottom: 20px;
    right: 10px;
    align-items: center;
    justify-content: flex-end;
    z-index: 15;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
}

.scrollTop:hover {
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

.footer__gfx {
    animation: gfx 40s ease-in 2 alternate;
    -moz-animation: gfx 40s ease-in 2 alternate;
    -webkit-animation: gfx 40s ease-in 2 alternate;
}

@keyframes gfx {
    0% { color: purple }
    10% { color: gray }
    20% { color: blue }
    30% { color: red }
    40% { color: teal }
    50% { color: purple }
    60% { color: olive }
    70% { color: green }
    80% { color: orange }
    90% { color: cyan }
    100% {
        color: yellow;
        transform: translateX(-300%);
    }
}
