@import '../../styles/vars';

.table {
  margin-top:5px;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.makeStyles-root-30 {
  max-width: 2500px !important;
}



.purchaseListSelectionMenu {
  margin-top: 20px;
  display: flex;
  align-items: center;

  .selectAll {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .deselectAll {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

}