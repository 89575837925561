@import '../../styles/vars';

.departmentlist {

  &__wrapper {
    min-width: 900px;
    width: $maxTableWidth;
    margin: 0 auto;
  }

  &__title {
    flex: 1 1 100%;
    padding-bottom: 1em;
  }

  &__defaultDepartment {
    background-color: $grey-5;

    & td {
      font-weight: 600;
    }
  }

  &__pattern {
    font-family: 'DejaVu Sans Mono', monospace !important;
  }

  &__sortLabel {
    color: white !important;

    svg {
      color: white !important;
    }
  }
}
