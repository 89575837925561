.NotificationsContainer {
  &__wrapper {
    position: fixed;
    top: 0;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: .4rem;
    z-index: 1000;
  }

  &__AlertWrapper {
    top: 20px;
    z-index: 1400;
    position: relative;
  }
}
