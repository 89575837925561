@import "./src/styles/vars";

.summary {

  min-width: 400px;
  width: 30%;

  @media (max-width: $screen-medium) {
    max-width: 100%;
  }

  margin: 1em;

  em {
    font-weight: 600;
  }

  &__title {
    font-size: 1.2em;
    padding: 10px 0;
  }

  &__details {
    size: 0.7em;

    li {
      padding-bottom: 5px;
    }
  }
}
