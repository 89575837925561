body {
  font-size: 14px;
  line-height: 1.25rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'MontAS', sans-serif;
  margin: 0;
}

h1 {
  font-size: 1.25rem;
  line-height: 1.3rem;
}

h2 {
  font-size: 1rem;
  line-height: 1.1rem;
}

h3 {
  font-size: .8rem;
  line-height: 1.1rem;
  margin-bottom: 0.5rem;
}

h5 {
  font-size: 22px;
}
