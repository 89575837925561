@import '../../../styles/vars';

.purchaseTable {

  &__assetExcessivePrice {
    background-color: rgba(255, 145, 145, 0.44);
  }

  &__statusAndCheckbox{
    display: flex;
  }

  &__statusIcons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    gap: 0.25rem 0;
  }

  &__assetImg {

    &__container {
      display:flex;
      flex-direction: row;
      gap: 0.5rem;
    }

    width: 200px;

    & :hover {
      cursor: pointer;
    }

    @media (max-width: 1800px) {
      width: 180px;
    }

    @media (max-width: 1500px) {
      width: 150px;
    }

    &__iconBackground {
      width: 30px;
      height: 30px;
      text-align: center;
      background-color: #ffffff;
      border-radius: 50px;
      padding: 8px 0 0 0;
      position: relative;
      cursor: pointer;
      z-index: 10;
      box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.1), 0px 1px 8px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
      transition: all 0.3s;
      top: 37px;
      left: 7px;
    }

    &__icon {
      svg {
        width: 18px;
        height: 18px;
        transform: translateY(-2px);

        path {
          fill: $black;
        }

        line {
          stroke: $black;
        }
      }
    }
  }

  &__credit {
    overflow: hidden;
    max-width: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__assetForeignId {
    display: -webkit-box;
    max-width: 15em;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__department {
    & a {
      color: rgba(0, 0, 0, 0.87);
      transition: all 0.3s;
    }
  }

}
