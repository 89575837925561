@import '../../styles/vars';

.login {

  &__wrapper h1 {
    font-size: 1.1rem;
    padding: 2rem 0;
    font-weight: 400;
  }

  &__form {
    margin-top: 1.25rem;
    position: relative;
    overflow: hidden;
    max-width: 25%;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width: $screen-medium) {
      max-width: 31.25rem;
    }

    &__normalInput {
      margin: 1rem 0 !important;
    }

    &__username:after,
    &__password:after {
      border-color: $bubblegum-red !important;
    }

    &__inputlabel {
      color: $black !important;
    }
  }
}

.MuiFormLabel-root {
  font-size: .8rem !important;
}
