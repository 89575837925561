@import '../../styles/vars';

.notfound__wrapper {
  width: 80%;
  margin: 10% auto 0 auto;

  & h1 {
    padding: 1rem 0 2rem 0;
    font-weight: 300;
    font-size: 2rem;
    line-height: 2.25rem;
  }

  & p {
    line-height: 1.45rem;
    font-size: 1rem;
    padding: 0 0 2rem 0;
  }

  & a {
    color: #000000;
    font-weight: 700;
    text-align: center;
    transition: all 0.3s ease-in-out 0s;
  }

  & a:hover {
    color: $as-blue;
    opacity: 0.75;
    text-decoration: none;
  }

  & a.active::after {
    transform: scale(0);
  }
}

