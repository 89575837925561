.vendorCreation {
    &__wrapper{
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 3em;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-auto-rows: 50px;
        grid-gap: 3em;
        margin-top: 5em;
        grid-template-areas:
            "a a"
            "b b"
            "firstName-input lastName-input"
            "error-message save-button";
    }

    &__input__firstName {
        grid-area: firstName-input;
    }

    &__input__lastName {
        grid-area: lastName-input;
    }

    &__error {
        grid-area: error-message;
    }

    &__saveButton {
        grid-area: save-button;
    }
}
