@import '../../../styles/vars';

.assetDetails {

  &__drawer {
    background-color: #333333;
    width: 40vw;
    height: 100vh;
    max-width: 40em;
    word-break: break-all;
    color: #ffffff;
    overflow-y: auto;
    box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.4), 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);

    & h1 {
      font-size: clamp(1em, 1vw, 1.5em);
      padding-bottom: .25em;
    }

    & span {
      font-size: clamp(.65em, 1vw, 1em);
    }

    & a {
      color: #ffffff;

      &:hover {
        color: $bubblegum-red;
        text-decoration: none;;
      }
    }

  }

  &__content {
    word-break: break-all;
  }

  &__closeButton {
    cursor: pointer;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: #333333;
    color: #fff;
    border-radius: 50px;
    padding: 7px 0 0 0;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 10;
    box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.1), 0px 1px 8px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
    transition: all 0.3s;

    &:hover {
      color: #333;
      background-color: #fff;
    }
  }

  &__actions {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    gap: .5rem;

    &__button {
      width: 40px;
      height: 40px;
      text-align: center;
      background-color: #333333;
      color: #fff;
      border-radius: 50px;
      padding: 8px 0 0 0;
      position: relative;
      cursor: pointer;
      z-index: 10;
      box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.1), 0px 1px 8px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
      transition: all 0.3s;

      &:hover {
        color: #333;
        background-color: #fff;
      }

      &--disabled {
        :hover {
          cursor: not-allowed !important;
        }
      }
    }

    &__restrictionNoteIcon {

      &:hover {
        & svg {
          path {
            fill: #000000;
          }

          line {
            stroke: #000000;
          }
        }
      }

      &--disabled {
        & svg {
          path {
            fill: $bubblegum-red;
          }

          line {
            stroke: $bubblegum-red;
          }
        }
      }
    }
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background: #1313136b;
  }

  &__thumbnailContainer {
    position: relative;
  }

  &__thumbnail {
    display: flex;
  }

  &__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .8;
    color: #000000;
  }

  &__headerGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    margin: 0 auto;
    padding: 1em 1.5em;

  }

  &__caption,
  &__price,
  &__onlineTime,
  &__restrictionNote,
  {
    padding: 1.5em 1.5em;
  }

  &__resolutionStrategy {
    font-style: italic;
  }


  &__agreements {
    margin: 1em auto;
    width: 90%;
  }

  &__switches {
    margin-left: 1.5em;

    & span {
      font-size: clamp(1.3em, 1vw, 2em);
      font-weight: bold;
    }
  }

  &__price {
    &--flex {
      display: flex;
      align-items: center;
      gap: 1em 0.5em;
    }
  }

  &__DatePickerGrid {
    display: flex;
    gap: 0 0.5em;

    & .MuiInput-root {
      color: #ffffff !important;
    }

    & .MuiInput-underline:before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "\00a0";
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid rgba(256, 256, 256, 0.42);
      pointer-events: none;
    }

    & .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid rgba(256, 256, 256, 0.72);
    }

    & .MuiInput-colorSecondary.MuiInput-underline:after {
      border-bottom-color: #ffffff;
    }

    & svg {
      fill: rgba(256, 256, 256, 0.95)
    }
  }

  &__onlineTimeAmount {
    display: flex;
    gap: 0 0.5em;
    padding: 0 1.5em;
    align-items: baseline;

    & h1 {
      font-weight: normal;
      font-size: clamp(1em, 1vw, 1.3em);
    }
  }

  &__buttonGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
    padding: 2em 1.5em;
  }

  &__restrictionNote {
    padding: 0 1.5rem 0.5rem 1.5rem;
    display: flex;
    grid-gap: 1em;
    gap: .5em;
    align-items: baseline;

    h3 {
      color: $bubblegum-red;
    }

    &--topPadding {
      padding-top: 1rem;
    }
  }

  &__enrichmentStatus {
    background-color: #ffffff;
    color: #000000;

    display: flex;
    flex-direction: column;

    &--flexColumn {
      display: flex;
      flex-direction: column;
      padding: 1rem 1.5rem 0 1.5rem;
    }

    &--flexRow {
      display: flex;
      gap: 0.5rem;
    }

    &--lastUpdated {
      padding: 0.5rem 1.5rem;
    }

    &--alertHeader {
      color: $bubblegum-red;
    }
  }

  &__collageChildren {
    padding: 0.5rem 1.5rem;

    &__titleWrapper {
      display: flex;
      gap: 1rem;
    }

    &__wrapper {
      padding: 1rem 0;
    }

    &__child {
      display: flex;
      gap: 1rem;
    }

    &__infoWrapper {
      display: flex;
      flex-direction: column;
    }

    &__thumbnail {
      width: 4rem;
    }
  }
}

