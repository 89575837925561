@import '../../styles/vars';

.mainNav {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 1;
  margin: 0 auto;
  text-transform: uppercase;
  height: 25px;
}

.mainNav a {
  font-size: .8rem;
  position: relative;
  padding: 0 1rem;
  color: $black;
  font-weight: 700;
  text-align: center;
  transition: all 0.3s ease-in-out 0s;
}

.mainNav .active {
  color: $bubblegum-red;
  text-decoration-line: none;
}

.mainNav a:hover::after,
.mainNav a.active::after {
}

.mainNav a:hover {
  color: $bubblegum-red;
}

.mainNav a:hover {
  opacity: 0.75;
  text-decoration: none;
}

.mainNav a.active::after {
  transform: scale(0);
}

.logoutMenu {
  padding-right: 0 !important;
  display: flex;
  position: relative;
  align-items: flex-start !important;
  margin-top: -19px !important;
}



