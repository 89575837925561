@import '../../../styles/vars';

.popoverContentWrapper {
  padding: 20px 0 20px 20px;

  .jobs {
    display: block;
  }
  .heading {
    margin-bottom: 10px;
  }

  .searchWrapper {
    width: 450px;
    display: flex;

    .search {
      width: 300px;

      .textfield {
        width: 100%;
        margin-right: 0px;
      }

      .button {
        background-color: $bubblegum-red !important;
        color: #ffffff !important;
        height: 30px;
      }
    }

  }

  .actionButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 200px;
  }
  .selectButtons .WithStyles\(ForwardRef\(Button\)\)-root-20 {
    color: #4f4f4f;
    font-size: 0.65rem;
    width: 49%;
    margin-right: 0px;
  }
  .selectButtons .WithStyles\(ForwardRef\(Button\)\)-root-20:hover {
    color: white;
  }
  .selectButtons {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .deselectAll {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-left: 10px;
  }
}
