@import '../../../styles/vars';

.billableConfirmModal {
  &__Buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3em;
    grid-auto-rows: 40px;
    align-self: end;
    padding-top: 2em;
  }

  &__content {
    & p {
      padding-top: 1.4em;
    }

    & span {
      color: #ffffff;
      font-weight: 600;
    }

  }
}
