$white: #ffffff;
$black: #000000;
$dark-grey: #878787;

$grey-3: #828282;
$grey-5: #E0E0E0;
$blue: #2e89f8;
$as-blue: #0000ff;
$light-as-blue: rgba(0, 0, 255, 0.7);
$bubblegum-red: #FF0954;
$bubblegum-yellow: #FFCE11;
$bubblegum-blue: #0288D1;
$bubblegum-green: #6FCF97;
$bubblegum-green__dark: #50af78;


$screen-medium: 768px;
$purchasefilter-breakpoint: 1300px;

$maxTableWidth: 90%;
