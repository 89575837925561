@import '../../styles/vars';

.creditadvicedetailheader {
  display: grid;
  grid-template-columns: auto;
  grid-template-areas:
          "title preview"
          "vendor empty"
          "personOfContact status";

  &__title {
    grid-area: title;
    font-weight: 400;
    padding: 2em;
  }

  &__preview {
    grid-area: preview;
    justify-self: end;
    font-weight: 400;
    padding: 2em 2em 0 0;
    min-height: 10em;

    &Icon {
      transition: all 0.3s;
      fill: $grey-3;
    }

    & a:hover {
      .creditadvicedetailheader__previewIcon {
        fill: $bubblegum-blue
      }
    }

    & span {
      font-size: 0.8em;
      text-align: center;
      margin: 3px auto 0 auto;
      display: block;
    }
  }

  &__previewButton {
    padding: 1em 1em .7em 1.4em;
    border: 1px solid $grey-3;
    border-radius: 10px;
  }

  &__vendor {
    display: grid;
    grid-area: vendor;
    padding: 2em 0 1em 3em;

    & h6 {
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 4px;
    }

    & span {
      font-weight: 400;
    }
  }

  &__vendorFinance {
    padding-top: 2em;

    & div {
      margin-bottom: 1em;
    }
  }

  &__personOfContact {
    grid-area: personOfContact;
    padding: 0 0 1.5em 3em;
    display: flex;
    align-items: center;

    & div:first-child {
      padding-right: 1em;
    }

    & .MuiInputBase-root {
      height: 50px;
      width: 12em;
    }
  }

  &__personOfContactForm {
    display: flex;
  }

  &__personOfContactButton {
    background: none;
    border: none;

    &:focus {
      outline: none;
    }
  }

  &__personOfContactIcon {
    margin-left: 1em;

    &:hover {

      path {
        fill-opacity: 1;
        fill: $bubblegum-green
      }

    }
  }

  &__status {
    grid-area: status;
    display: grid;
    padding: 0 3em 1.5em 0;
    grid-template-columns: auto;
    grid-template-areas:
            "statusIcon statusLabel"
            "statusSummary changedBy";
    align-self: center;
    justify-items: end;
    justify-self: end;
  }

  &__statusIcon {
    margin: 0 auto;
    grid-area: statusIcon;

    &Icon {
      width: 45px;
    }
  }

  &__statusLabel {
    grid-area: statusLabel;
    margin-top: 2.3em;
  }

  &__statusSummary {
    grid-area: statusSummary;
    display: flex;
    align-items: flex-end;
    padding: 0 10px;
  }

  &__statusChangedBy {
    text-align: right;
    grid-area: changedBy;
    font-size: 12px;
  }
}

.creditadvicedetail {

  &__heading {
    cursor: pointer;
  }

  &__wrapper {
    min-width: 900px;
    width: $maxTableWidth;
    margin: 0 auto;
  }

  &__table {
    font-weight: 600;
    position: relative;
    z-index: 5;
    overflow-x: scroll !important;
  }

  &__tablehead {
    background-color: $bubblegum-red;
    height: 60px;
    display: flex;
    padding: 0 30px;

    & h6 {
      color: #ffffff;
      font-weight: 600;
      font-size: 1.145em;
      align-self: center;
      flex-grow: 1;
    }

    & button {
      height: 34px;
      margin-left: 1em;
      align-self: center;
      background-color: #ffffff;
      color: #000;
      text-transform: none;
      font-weight: 600;
      max-width: 10em;
      box-shadow: none;
      justify-self: end;
      font-size: 14px;
    }
  }

  &__legendHeader {
    th {
      font-weight: 700;
    }
  }

  &__prevStateButton {
    background-color: #efefef !important;
    color: #000000 !important;

    &:hover {
      background-color: #d4d4d4 !important;
      box-shadow: none !important;
    }
  }

  &__nextStateButton {
    background-color: #4f4f4f !important;
    color: #ffffff !important;

    &:hover {
      background-color: $black !important;
      box-shadow: none !important;
      color: #ffffff;
    }
  }

  &__checkbox {
    & :hover {
      color: $bubblegum-red;
    }
  }

  &__actions {
    white-space: nowrap;

    & .creditadvicedetail__delete {
      padding-right: 1em;
      top: 7px;
      position: relative;
    }
  }

  &__spinner {
    display: flex;
    position: absolute;
    top: 30%;
    left: 10%;

    span {
      margin-top: -2px;
    }
  }

  &__rowMinwidth {
    min-width: 9em;
  }

  &__addIconRow {
    padding-right: 50px !important;
  }

  &__delete {
    transition: all 0.3s;
    margin-left: 1em;

    &Icon {
      transition: all 0.3s;
      fill: $grey-3;

      &:hover {
        fill: $bubblegum-red
      }
    }

    &--disabled {
      padding-right: 1em;
      padding-left: 1em;
      top: 9px;
      position: relative;

      .creditadvicedetail__deleteIcon {
        transition: all 0.3s;
        fill: #ddd;
      }
    }
  }

  &__bulkDelete {
    height: 34px;
    margin-left: 1em;
    align-self: center;
    text-transform: none;
    font-weight: 600;
    max-width: 10em;
    box-shadow: none;
    justify-self: end;
    font-size: 14px;
    min-width: 10em;
  }

  &--alignRight {
    padding-right: 30px !important;
  }

  &__remaining {
    white-space: nowrap;
    font-size: 0.875rem !important;
  }

  &__total {
    font-size: 0.875rem !important;
    font-weight: 600 !important;
    padding: 2em 30px !important;
    white-space: nowrap !important;
  }

  &__addIcon {
    margin: -19px auto 0;
    display: block;
    position: relative;
    z-index: 10;
    transition: all .3s linear;

    &:hover,
    &:active,
    &:focus {
      transform: rotate(180deg);
    }

    &--idle {
    }

    &--active {
      svg {
        transform: rotate(225deg);

        circle {
          fill: black !important;
        }

        &:hover {
          transform: rotate(225deg);
        }
      }
    }

  }
}






