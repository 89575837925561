@import '../../styles/vars';

.backOffice {

    &__wrapper {
        padding: 2rem;
        margin: 0 auto;
    }

    &__titleWrapper {
        display: flex;
        justify-content: space-between;
        padding-right: 5rem;
    }

    &__subtitle {
        padding-top: 2em;
    }

    &__actions {
        display: flex;

        &__label {
            padding: 1rem;
        }
    }


}
