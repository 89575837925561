
.CreditAdviceBatchJobSearchResults{
    margin-top: 0px;
    max-height: 250px;
     overflow: scroll;
     overflow-x: hidden;
    .MuiTableHead-root {
      position: sticky;
      top: 0;
      background: #fff; /* Optional: Set background color for the header */
      z-index: 1; /* Ensure the header stays above the body content */
    }
    .itemWrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        .count{
            margin-right: 10px;
        }
       .companyName {
           display: flex;
           align-items: center;
       }
    }
}



