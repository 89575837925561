@import 'src/styles/vars';

.assetActions {

  &__drawer {
    background-color: #ffffff;
    width: 35vw;
    height: 95vh;
    max-height: 1000px;
    max-width: 35em;
    color: #333333;
    overflow-y: scroll;
    box-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.4), 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);

    & h1 {
      font-size: clamp(1.2rem, 1vw, 1.6rem);
    }

    & span {
      font-size: clamp(.65em, 1vw, 1em);
    }

    & a {
      color: #ffffff;

      &:hover {
        color: $bubblegum-red;
        text-decoration: none;;
      }
    }
  }

  &__section {
    margin-top: 1rem;
  }

  &__spinner {
    display: grid;
    justify-content: center;
    align-content: center;
    position: relative;
    top: 10vh;

    &--circularProgress {
      margin: 0 auto;
    }

    &--message {
      padding-top: 1rem;
    }

  }

  &__content {
    padding: 1rem;
  }

  &__paragraph {
    padding-top: 1rem;

    font-size: 0.7rem;
    font-style: italic;

    & span {
      font-weight: 600;
    }

    &--warning {
      padding: 1rem;
      background-color: $bubblegum-red;
      color: $white;
      border-radius: 2px;
      margin-top: 0.5rem;
      transition: all 0.3s;
    }
  }

  &__buttonGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 1.5rem 0;

    @media (max-width: 1150px) {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: -25px;
      padding-bottom: 15px;
    }
  }


  &__pickerGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    @media (max-width: 1150px) {
      display: flex;
      flex-direction: column;
      gap: 0rem;
      margin-top: -25px;
      padding-bottom: 15px;
    }
  }

  &__picker {
    margin: 1rem 0;
  }



  &__metadata {

    &__header {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border-bottom: 1px solid #2e2e2e2e;
      gap: 1rem;

      h2 {
        color: $bubblegum-red;
        font-size: 0.9rem;
        padding-bottom: 0.5rem;
      }
    }

    padding: 1rem 0;

    h1 {
      padding-bottom: 0.5rem;
      font-size: clamp(.9em, 1vw, 1.2em);
    }

    span {
      font-size: clamp(0.65em, 1vw, 1em);
    }

    &__valueGrid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      word-break: break-all;
    }

    &__wrapper {
      overflow-y: scroll;
      height: calc(100vh - 20em);
    }

  }

  &__repurchase {
    &__wrapper {
      overflow-y: scroll;
      height: calc(100vh - 21em);
      margin-top: 8px;
      margin-bottom: 10px;
    }
  }

  &__restrictionNote {
    &__header {
      padding-bottom: 1rem;
    }
  }

}

// refactor to custom button style
.MuiButton-containedSizeSmall {
  padding: 8px 8px !important;
}
