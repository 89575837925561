@import '../../styles/vars';

.spinner {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 48.5%;

  &__inline {
    display: flex;
    top: 30%;
    left: 10%;

    span {
      margin-top: -2px;
    }
  }
}

.spinner__modal {
  display: block;
  position: relative;
  top: unset;
  left: unset;

  & svg {
    color: #fff;
  }
}
