@import './src/styles/vars';

.addLineItem {

  &__card {
    width: 80%;
    margin: 0 auto;
    z-index: 1;
    padding-top: 3em;
  }

  &__tablehead {
    background-color: #333;
    height: 60px;
    display: flex;

    & th {
      color: #ffffff;
    }
  }

  &__actions {
    padding: 1em 1em;
    display: flex;
    justify-content: space-between;
  }

  &__button {
    background-color: black !important;
  }

}

  .potentialCreditLineItem {

    &__PaginationWrapper {
      display: flex;
    }

    &__pagination {
      margin: 5px auto 0 auto;
      justify-content: space-between;
      display: flex;
      list-style: none;
      outline: none;
    }

    &__pagination > .active > a {
      color: $bubblegum-red;
    }

    &__pagination > li {
      padding: 0 .3em;
    }

    &__pagination > li > a {
      padding: .4em;
      outline: none;
      font-weight: 600;
    }

    &__pagination > li > a:hover {

      cursor: pointer;
      color: $bubblegum-red;
      text-decoration: none;
    }

    &__pagination > .active > a, &__pagination > .active > span, &__pagination > .active > a:hover, &__pagination > .active > span:hover, &__pagination > .active > a:focus, &__pagination > .active > span:focus {
      outline: none;
      text-decoration: none;
    }

    &__pagination > li > a, &__pagination > li > span {
      color: #000
    }

    &__pagination > li:first-child > a, &__pagination > li:first-child > span, &__pagination > li:last-child > a, &__pagination > li:last-child > span {
      border-radius: unset;
      font-size: 1.5em;
      font-weight: 300;
      line-height: 1.1em;
      text-decoration: none;
    }
  }

  .creditadviceAddLineItem {

    &__creditadviceIcon {
      width: 35px;
      height: 35px;
    }

    &__addIcon {

      &:hover {
        circle {
          fill: $bubblegum-green
        }
      }

      circle {
        transition: all 0.3s ease-in-out;
      }
    }
  }

